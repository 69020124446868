<button mat-icon-button (click)="showNav = !showNav">
  <mat-icon>menu</mat-icon>
</button>
@if (showNav) {
  <mat-nav-list style="width: 250px">
    <a mat-list-item routerLink="/">Dashboard</a>
    <a mat-list-item *ngIf="showPartners" routerLink="/partners">Partners</a>
    <a mat-list-item *ngIf="showUsers" routerLink="/users">Users</a>
    <a mat-list-item *ngIf="showLambdaStore" routerLink="/lambda">Lambda Store</a>
  </mat-nav-list>
}
