import { Component, inject } from '@angular/core';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
})
export class SidenavComponent {
  auth = inject(AuthService);
  showPartners!: boolean;
  showUsers!: boolean;
  showLambdaStore!: boolean;
  showNav = true;

  ngOnInit(){
    this.auth.ready().subscribe(isReady => {
      if(isReady){
        this.showPartners = this.auth.canView('partner');
        this.showUsers = this.auth.canView('user');
        this.showLambdaStore = this.auth.canView('lambda');
      }
    })
  }
}
